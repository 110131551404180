/* eslint-disable react/jsx-curly-brace-presence */
import React, { memo } from 'react';

import Image from 'next/image';
import Link from 'next/link';

import appApple from 'assets/apple-store.webp';
import amexCardIcon from 'assets/cards-icons/icon-card-amex.png';
import bbCardIcon from 'assets/cards-icons/icon-card-bb.png';
import boletoCardIcon from 'assets/cards-icons/icon-card-boleto.png';
import eloCardIcon from 'assets/cards-icons/icon-card-elo.png';
import jcbCardIcon from 'assets/cards-icons/icon-card-jcb.png';
import mastercardCardIcon from 'assets/cards-icons/icon-card-mastercard.png';
import visaCardIcon from 'assets/cards-icons/icon-card-visa.png';
import seloPix from 'assets/cards-icons/selos-pix.webp';
import { ContainerGlobal } from 'components/common/container';

import NewsLetter from './NewsLetter';
import * as S from './styles';

// <div>
// <h5>SOBRE O SIDNEY OLIVEIRA</h5>

// <Link href="/sobre-a-empresa">Sobre a Empresa</Link>
// <Link href="/sobre-a-marca">Sobre a Marca</Link>
// <Link href="/sobre-os-produtos">Sobre os Produtos</Link>
// </div>

// <div>
// <h5>AJUDA</h5>

// <Link href="/central-do-cliente/pedidos" passHref>
//   Acompanhe seu Pedido
// </Link>
// <a
//   href="https://www.ultrafarma.com.br/fale-conosco"
//   target="_blank"
//   rel="noopener noreferrer"
// >
//   Atendimento por E-mail
// </a>

// <a
//   href="/politicas-de-privacidade"
//   target="_blank"
//   rel="noopener noreferrer"
// >
//   Políticas de Privacidade
// </a>
// </div>

const Footer: React.FC = () => {
  return (
    <S.Footer>
      <NewsLetter />

      <div className="informations-footer">
        <ContainerGlobal>
          <div className="links">
            <h5>Sobre Ultrafarma Pet</h5>

            <Link href="/pagina/quem-somos" passHref>
              <a href="replaced" className="link">
                Quem somos
              </a>
            </Link>
          </div>

          <div className="links">
            <h5>Ajuda</h5>

            <Link href="/cadastro" passHref>
              <a href="replaced" className="link">
                Cadastre-se Aqui
              </a>
            </Link>

            <Link href="/pagina/duvidas-sobre-a-minha-entrega" passHref>
              <a href="replaced" className="link">
                Dúvidas sobre a Entrega
              </a>
            </Link>

            <Link href="/pagina/como-comprar" passHref>
              <a href="replaced" className="link">
                Como Comprar
              </a>
            </Link>

            <Link href="/pagina/politica-de-privacidade" passHref>
              <a href="replaced" className="link">
                Políticas de Privacidade
              </a>
            </Link>

            <Link href="/pagina/trocas-e-devolucoes" passHref>
              <a href="replaced" className="link">
                Trocas e Devoluções
              </a>
            </Link>

            <Link href="/central-do-cliente/pedidos" passHref>
              <a href="replaced" className="link">
                Acompanhe seu Pedido
              </a>
            </Link>

            <Link
              href="https://atendimento.ultrafarma.com.br/hc/pt-br"
              passHref
            >
              <a href="replaced" className="link">
                Atendimento ao cliente
              </a>
            </Link>
          </div>

          <div className="selos">
            <div className="payment">
              <h5>Formas de pagamento</h5>

              <p>
                Parcele em até 3x sem juros nos cartões de crédito
                {'(parcelas mínimas de 20 reais)'}
              </p>

              <div className="cards">
                <Image
                  className="image-card"
                  src={boletoCardIcon}
                  alt="Bandeira cartão"
                />

                <Image
                  className="image-card"
                  src={mastercardCardIcon}
                  alt="Bandeira cartão"
                />

                <Image
                  className="image-card"
                  src={visaCardIcon}
                  alt="Bandeira cartão"
                />

                <Image
                  className="image-card"
                  src={amexCardIcon}
                  alt="Bandeira cartão"
                />

                {/* <Image
                  className="image-card"
                  src={eloCardIcon}
                  alt="Bandeira cartão"
                /> */}

                {/* <Image
                  className="image-card"
                  src={jcbCardIcon}
                  alt="Bandeira cartão"
                /> */}

                <Image className="image-card" src={seloPix} alt="Pix" />
              </div>
            </div>
          </div>
        </ContainerGlobal>
      </div>
    </S.Footer>
  );
};

export default memo(Footer);
